<template>
    <r-e-dialog :title="title" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="5vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel" width="800px">
        <el-form ref="formPublish" label-width="80px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="角色名称" prop="comment">
                    <el-input v-model="formPublish.comment" style="width: 600px;" placeholder="请填写角色名称"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="角色备注" prop="name">
                    <el-input type="textarea" :rows="2" v-model="formPublish.name" style="width: 600px;"
                              placeholder="请填写角色备注"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="角色权限">
                    <div style="height: 250px;overflow:scroll;width: 600px;">
                        <el-tree ref="tree" :data="treeData" show-checkbox node-key="uuid" highlight-current
                                 :props="defaultProps"/>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="小区权限">
                    <div style="height: 250px;overflow:scroll;width: 600px;">
                        <el-checkbox-group v-model="formPublish.communityUuids">
                            <el-checkbox :label="item.uuid" v-for="(item,index) in communityUuids"
                                         :key="index">{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {roleSave, roleUpdate} from "@/api/role";
    import {getMenuTree} from "@/api/menu";
    import {getBaseInfoCommunityApi} from "@/api/house-distribution";
    import {MessageSuccess, MessageError} from "@custom/message";
    export default {
        name: "dialog-save-menu",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    uuid: null,
                    name: '',
                    comment: '',
                    uuids: null,
                    communityUuids: []
                },
                title: "",
                type: null,
                rules: {
                    name:[{required: true, message: '请输入角色名称', trigger: 'blur'}],
                    comment:[{required: true, message: '请输入角色备注', trigger: 'blur'}],
                },
                treeData: [],
                defaultProps:{
                    children: 'children',
                    label: 'menuName'
                },
                communityUuids: [],
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            }
        },
        mounted() {
            this.getMenuTree();
            this.getBaseInfoCommunityApi();
        },
        methods: {
            openDialog(type, data) {
                this.type = type;
                this.title = type === 'edit' ? '修改角色' : '添加角色';
                if (data) {
                    let {uuid, name, comment, uuids, communityUuids = null} = data;
                    communityUuids = communityUuids || [];
                    this.formPublish = {uuid, name, comment, uuids, communityUuids};
                    if (uuids && uuids.length !== 0) {
                        for (let value of uuids) {
                            this.$nextTick(() => this.$refs['tree'].setChecked(value, true));
                        }
                    }
                }
                this.dialogVisible = true;
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.formPublish = {uuid: null, name: '', comment: '', uuids: null,communityUuids:[]};
                this.$emit('handleSearch');
                this.dialogVisible = false;
            },
            clickSubmit() {
                let that = this;
                // 表单提交
                that.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let {uuid, name, comment, uuids, communityUuids} = that.formPublish;
                        uuids = this.$refs['tree'].getCheckedKeys().concat(this.$refs['tree'].getHalfCheckedKeys());
                        if (this.type === 'add') that.roleSave({name, comment, uuids, communityUuids});
                        else that.roleUpdate({uuid, name, comment, uuids, communityUuids});
                    }
                });
            },
            roleSave(data){
                let that = this;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                roleSave(data).then(res => {
                    MessageSuccess("添加成功");
                    that.clickCancel();
                }).finally(() => loading.close());
            },
            roleUpdate(data){
                let that = this;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                roleUpdate(data).then(res => {
                    MessageSuccess("修改成功");
                    that.clickCancel();
                }).finally(() => loading.close());
            },
            getMenuTree() {
                let that = this;
                getMenuTree(this.formSearch).then(res => {
                    let {list} = res;
                    that.treeData = list;
                });
            },
            getBaseInfoCommunityApi(){
                let that = this;
                getBaseInfoCommunityApi().then(res => {
                    let {list} = res;
                    that.communityUuids = list;
                });
            }
        }
    }
</script>

<style scoped>

</style>